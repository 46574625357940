:root {
  --sitecolor: #00FF00;
  --textcolor: #ffffff;
  --bgcolor-pri: #000000;
  --bgcolor-sec: #303338;
  --linkcolor: #ffffff;
  --linkcolor-hov: #00FF00;
  --s-slider-prev: url('/images/arrow-left-green.svg');
  --s-slider-next: url('/images/arrow-right-green.svg');
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  cursor: pointer;
  border: 1px solid var(--textcolor);
  color: var(--textcolor);
  padding: 4px 10px 3px 14px;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 1.44444444;
  font-family: 'urw-din-condensed', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  display: inline-block;
  transition: all 0.4s cubic-bezier(0.22, 1, 0.36, 1);
  min-width: 180px;
}
@media (hover: hover) and (pointer: fine) {
  .button:hover,
  .button:focus {
    border-color: var(--sitecolor);
  }
}
.button:active {
  border-color: var(--sitecolor);
}
/*# sourceMappingURL=./screen-medium.css.map */